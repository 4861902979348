import "./App.css";
import "./assets/css/bootstrap.min.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
// import Layout from "./components/full-layout/full-layout";
import LayoutComponent from "./components/layout/layout";

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutComponent />
  }
])

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
