import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import "./layout.scss";
import SideBar from "../sidebar/sidebar";

import * as AOS from "aos";

// Importing SVG Images
import html5 from "../../assets/svg/html.svg";
import css3 from "../../assets/svg/css.svg";
import js from "../../assets/svg/js.svg";
import jq from "../../assets/svg/Jq2.svg";
import bs from "../../assets/svg/bs.svg";
import ag from "../../assets/svg/ag.svg";
import mt from "../../assets/svg/mt.svg";
import es from "../../assets/svg/es.png";
import ts from "../../assets/svg/ts.svg";
import rct from "../../assets/svg/rct.svg";
import node from "../../assets/svg/node.svg";
import npm from "../../assets/svg/npm.svg";
import express from "../../assets/svg/exp2.svg";
import mon from "../../assets/svg/mon.svg";
import mys from "../../assets/svg/ms.svg";
import git from "../../assets/svg/git.svg";
import st from "../../assets/svg/st.svg";
import ma from "../../assets/svg/ma.svg";
import stt from "../../assets/svg/stt.svg";
import vs from "../../assets/svg/vs.svg";
import aws from "../../assets/svg/aws.svg";
import awsLambda from "../../assets/svg/aws-lambda.svg";
import awsCF from "../../assets/svg/aws-cf.svg";
import CV from "../../assets/docs/Yuvaraj_MERN_Stack.docx";
// import prof from "../../assets/images/1.jpg";
import dart from "../../assets/svg/dart.svg";
import flutter from "../../assets/svg/flutter.svg";
import redux from "../../assets/svg/redux.svg";
import as from "../../assets/svg/as.svg";
import xCode from "../../assets/svg/xcode.svg";

let allRefs = [];

function LayoutComponent() {
  const Section_SC1 = React.createRef();
  const Section_SC2 = React.createRef();
  const Section_SC3 = React.createRef();
  const Section_SC4 = React.createRef();
  const Section_SC5 = React.createRef();

  const [activeSection, setActiveSection] = useState("S1");
  const [isLoadMore, setIsLoadMore] = useState(false);

  const setLoadMore = () => {
    console.log("Loaded");
    setIsLoadMore(!isLoadMore);
  };

  useEffect(() => {
    AOS.init();
    var timer = null;
    $(Section_SC1.current).addClass("active");
    allRefs.push(
      Section_SC1.current,
      Section_SC2.current,
      Section_SC3.current,
      Section_SC4.current,
      Section_SC5.current
    );
    window.addEventListener(
      "scroll",
      (event) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          handleScroll(null);
        }, 250);
      },
      false
    );
    handleScroll(null);
  }, []);

  const menuClick = (args) => {
    let sc = args;
    const currentRef =
      sc === "S1"
        ? Section_SC1.current
        : sc === "S2"
        ? Section_SC2.current
        : sc === "S3"
        ? Section_SC3.current
        : sc === "S4"
        ? Section_SC4.current
        : sc === "S5"
        ? Section_SC5.current
        : null;
    if (currentRef) {
      window.scrollTo(0, currentRef.offsetTop);
    }
  };

  const handleScroll = (event) => {
    for (const [rIndex, rValue] of allRefs.entries()) {
      $(rValue).removeClass("active");
      let eDoc = ReactDOM.findDOMNode(rValue).getBoundingClientRect();
      if (
        Math.round(((eDoc.height - Math.abs(eDoc.top)) / eDoc.height) * 100) >
        75
      ) {
        $(rValue).addClass("active");
        setActiveSection(`S${rIndex + 1}`);
        break;
      }
    }
  };

  return (
    <div className={"min-h-100 d-flex col"}>
      <div className={"col h-100"}>
        {/* ----------- Old Design Content ---------- */}
        <div
          className={"bg-white d-flex flex-grow-1 s1 position-relative"}
          ref={Section_SC1}
          style={{ minHeight: "100vh" }}
        >
          <div className={"leftClip"}></div>
          <div
            className={
              "mainContent col-12 col-md-11 px-md-4 mx-auto d-flex flex-column flex-md-row justify-content-md-start justify-content-center align-items-center"
            }
          >
            <div
              className={
                "floatingSection app-bg-dark d-flex justify-content-center align-items-center col-2 col-md-4"
              }
              data-aos="fade-down"
              data-aos-delay="75"
              data-aos-duration="1000"
            ></div>
            <div
              className={"objectiveContent col-10 col-md-8"}
              data-aos="fade-down"
              data-aos-delay="75"
              data-aos-duration="1000"
            >
              <div className={"px-md-5 px-2 w-md-75 w-100 ml-auto"}>
                <h4 className={"app-color-grey fs-5"}>Hello Everyone!</h4>
                <h4 className={"fs-1 d-flex flex-row"}>
                  <font className={"mr-3 app-color-dark fw-bold"}>I'M</font>
                  <font className={"app-color-green fw-bold mr-2"}>
                    YUVARAJ
                  </font>
                  <font className={"app-color-green fw-bold"}>GOPI</font>
                </h4>
                <h5 className={"info fs-5"}>
                  <font className={"mr-3 app-color-dark fw-bold"}>
                    Technical Specialist @CitiusTech
                  </font>
                </h5>
                <div className={"mt-4"}>
                  <h4 className="info m-0 d-flex flex-row justify-content-start align-items-start">
                    <font size="3" className="d-inline-block mr-3">
                      &#x2666;
                    </font>
                    <font>
                      I'm a <b>MERN</b> & <b>MEAN</b> Stack |{" "}
                      <b>React Native</b> Developer based on Chennai, Tamilnadu.
                    </font>
                  </h4>
                  <h4 className="info m-0 d-flex flex-row justify-content-start align-items-start">
                    <font size="3" className="d-inline-block mr-3">
                      &#x2666;
                    </font>
                    <font>
                      Experienced in transforming <b>UX</b> | <b>UI</b> designs
                      into high-quality functional web Applications.
                    </font>
                  </h4>
                  <h4 className="info m-0 d-flex flex-row justify-content-start align-items-start">
                    <font size="3" className="d-inline-block mr-3">
                      &#x2666;
                    </font>
                    <font>
                      I Develop Applications by analyzing the customer
                      requirements to deliver
                      <br></br>
                      <b>Reusable, Responsive, Optimized, Cross-Browser </b>
                      supported applications.
                    </font>
                  </h4>
                  <h4 className="info m-0 d-flex flex-row justify-content-start align-items-start">
                    <font size="3" className="d-inline-block mr-3">
                      &#x2666;
                    </font>
                    <font>
                      Experienced in &nbsp;
                      <b>
                        AWS Lambda, DynamoDB, CloudFormation, S3 Bucket, Layers,
                        AWS CLI, SAM CLI, IAM.
                      </b>
                    </font>
                  </h4>
                  <h4 className="info m-0 d-flex flex-row justify-content-start align-items-start">
                    <font size="3" className="d-inline-block mr-3">
                      &#x2666;
                    </font>
                    <font>
                      Current activity <b>Flutter & Dart</b> for Mobile
                      application development.
                    </font>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------- Old Design Content End --------- */}

        {/* <div
          className={"d-flex flex-grow-1 s1 position-relative section-a"}
          ref={Section_SC1}
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex flex-column col-12 justify-content-center">
            <div className="justify-content-center d-flex col-lg-10 mx-lg-auto col-12 position-relative align-items-center">
              <div className="floatindCard cardA d-flex align-items-center flex-row px-4 py-2">
                <div className="px-2" style={{ fontSize: "1.9rem" }}>
                  👋
                </div>
                <div className="px-2">
                  <div className="cardTopHeader">Hi I am</div>
                  <div className="cardTitle">Yuvaraj Gopi</div>
                </div>
              </div>
              <div className="floatindCard cardB d-flex align-items-center flex-row px-4 py-2">
                <div className="px-2">
                  <div className="cardTitle">MERN Stack</div>
                </div>
                <div className="px-2">
                  <img
                    src={rct}
                    width="30px"
                    height="auto"
                    alt="ReactJs Stack"
                  />
                </div>
              </div>
              <div className="floatindCard cardC d-flex align-items-center flex-row px-4 py-2">
                <div className="px-2">
                  <div className="cardTitle">MEAN Stack</div>
                </div>
                <div className="px-2">
                  <img
                    src={ag}
                    width="30px"
                    height="auto"
                    alt="Angular Stack"
                  />
                </div>
              </div>
              <div className="floatingImage">
                <img src={prof} width="100%" alt="Profile" />
              </div>
            </div>
            <div className="col-12 pt-5">
              <font size="7" className="text-center w-100 d-block mainHeader">
                Technical Lead II @ CitiusTech
              </font>
              <font size="6" className="text-center w-100 d-block subHeader">
                AWS | MERN & MEAN Stack | Freelancer
              </font>
              <font size="5" className="text-center w-100 d-block subHeader">
                Based in Chennai, Tamil Nadu, India
              </font>
            </div>
          </div>
        </div> */}
        <div
          className={"app-bg-dark d-flex flex-grow-1 s2 pb-5"}
          ref={Section_SC2}
          style={{ minHeight: "100vh" }}
        >
          <div className={"mainContent col-11 mx-auto px-4"}>
            <div className={"objectiveContent col pt-4"}>
              <h1
                contenttext="About Me"
                className={"text-center py-5 ttl1 app-font-EBold"}
                data-aos="fade-down"
                data-aos-delay="75"
                data-aos-duration="1000"
              >
                Myself
              </h1>
              <div className="col pt-5 d-flex flex-row justify-column-between align-items-center">
                <div className="col-12 d-flex flex-row flex-wrap justify-content-center px-4">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="75"
                    data-aos-duration="1000"
                    className="col-md-4 col-12 p-2 d-flex flex-row align-items-center"
                    style={{ height: "250px" }}
                  >
                    <div className="col ad h-100 d-flex flex-column align-items-start">
                      <font
                        className={
                          "app-font-bold app-color-green d-block ml-3 my-2"
                        }
                        style={{ fontSize: "3rem" }}
                      >
                        9<sup></sup>
                      </font>
                      <h1
                        className={"text-white fs-6 p-3 app-font-Md"}
                        style={{ wordSpacing: "3px" }}
                      >
                        Years of Work Experience in <b>IT</b> Industry
                      </h1>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="90"
                    data-aos-duration="1000"
                    className="col-md-4 col-12 p-2 d-flex flex-row align-items-center"
                    style={{ height: "250px" }}
                  >
                    <div className="col ad h-100 d-flex flex-column align-items-start">
                      <font
                        className={
                          "app-font-bold app-color-green d-block ml-3 my-2"
                        }
                        style={{ fontSize: "3rem" }}
                      >
                        10
                        <sup>+</sup>
                      </font>
                      <h1
                        className={"text-white fs-6 p-3 app-font-Md"}
                        style={{ wordSpacing: "3px" }}
                      >
                        Projects Handled in various sector (Education,
                        Automation, ITAAS, ERP, Healthcare) using JavaScript
                        Frameworks and Libraries
                      </h1>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="105"
                    data-aos-duration="1000"
                    className={
                      "col-md-4 col-12 p-2 d-flex flex-row align-items-center"
                    }
                    style={{ height: "250px" }}
                  >
                    <div
                      className={
                        "col ad h-100 d-flex flex-column align-items-start"
                      }
                    >
                      <font
                        className={
                          "app-font-bold app-color-green d-block ml-3 my-2"
                        }
                        style={{ fontSize: "3rem" }}
                      >
                        7
                      </font>
                      <h1
                        className={"text-white fs-6 p-3 app-font-Md"}
                        style={{ wordSpacing: "3px" }}
                      >
                        Online Courses / Certifications Done.
                      </h1>
                      <h1 className={"text-white fs-6 px-3 app-font-Md"}>
                        <b>LinkedIn Learning, Udemy, Pirple</b>.
                      </h1>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="120"
                    data-aos-duration="1000"
                    className={
                      "col-md-4 col-12 p-2 d-flex flex-row align-items-center"
                    }
                    style={{ height: "250px" }}
                  >
                    <div
                      className={
                        "col ad h-100 d-flex flex-column align-items-start"
                      }
                    >
                      <font
                        className={
                          "app-font-bold app-color-green d-block ml-3 my-2"
                        }
                        style={{ fontSize: "3rem" }}
                      >
                        6+
                      </font>
                      <h1
                        className={"text-white fs-6 p-3 app-font-Md"}
                        style={{ wordSpacing: "3px" }}
                      >
                        Worked as a Freelance <b>Fullstack</b> WEB | Mobile
                        (React Native) application developer.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"app-bg-dark d-flex flex-grow-1 s3 pb-5"}
          ref={Section_SC3}
          style={{ minHeight: "100vh" }}
        >
          <div className={"mainContent col-md-11 col-12 mx-auto px-md-4 px-1"}>
            <div className={"objectiveContent col pt-4"}>
              <h1
                contenttext="Intelligence"
                className={"text-center py-5 ttl1 app-font-EBold"}
                data-aos="fade-down"
                data-aos-delay="75"
                data-aos-duration="1000"
              >
                Skills
              </h1>
              <div
                className={
                  "col pt-5 d-flex flex-row flex-wrap align-items-center justify-content-center h-100"
                }
              >
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div
                      className={
                        "d-flex flex-row flex-wrap justify-content-between align-items-center mx-auto"
                      }
                    >
                      <div className={"col px-2"}>
                        <img
                          src={html5}
                          width="55px"
                          height="auto"
                          alt="HTML5"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img src={css3} width="55px" height="auto" alt="CSS3" />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={js}
                          width="55px"
                          height="auto"
                          alt="Javascript"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img src={jq} width="55px" height="auto" alt="JQuery" />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={bs}
                          width="55px"
                          height="auto"
                          alt="Bootstrap"
                        />
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      HTML5 / CSS3 / Javascript / JQuery / Bootstrap
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div
                      className={
                        "d-flex justify-content-between align-items-center mx-auto"
                      }
                    >
                      <div className={"col px-2"}>
                        <img
                          src={ag}
                          width="55px"
                          height="auto"
                          alt="Angular"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={mt}
                          width="55px"
                          height="auto"
                          alt="Angular Material"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={rct}
                          width="55px"
                          height="auto"
                          alt="React JS"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={redux}
                          width="55px"
                          height="auto"
                          alt="Redux"
                        />
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      Angular / Angular Material / React JS / Redux
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div className={"d-flex mx-auto justify-content-center"}>
                      <div className={"col px-2"}>
                        <img
                          src={es}
                          width="65px"
                          height="auto"
                          alt="Ecmascript"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={ts}
                          width="65px"
                          height="auto"
                          alt="Typescript"
                        />
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      ECMAScript / TypeScript
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div className={"d-flex mx-auto justify-content-center"}>
                      <div className={"col px-2"}>
                        <img
                          src={rct}
                          width="50px"
                          height="auto"
                          alt="React Native"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={flutter}
                          width="50px"
                          height="auto"
                          alt="Flutter"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img src={dart} width="50px" height="auto" alt="Dart" />
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-5"}
                    >
                      React Native / Flutter & Dart
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div
                      className={
                        "d-flex flex-row justify-content-between align-items-center mx-auto"
                      }
                    >
                      <div className={"col px-2"}>
                        <img
                          src={node}
                          width="45px"
                          height="auto"
                          alt="Node JS"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img src={npm} width="65px" height="auto" alt="NPM" />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={express}
                          width="105px"
                          height="auto"
                          alt="Express JS"
                        />
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      Node JS / NPM / Express JS
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div
                      className={
                        "d-flex flex-row justify-content-between align-items-center mx-auto"
                      }
                    >
                      <div className={"col px-2"}>
                        <img
                          src={mon}
                          width="35px"
                          height="auto"
                          alt="MongoDB"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img src={mys} width="95px" height="auto" alt="MySQL" />
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      MongoDB / MySQL
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div
                      className={
                        "d-flex flex-row justify-content-between align-items-center mx-auto"
                      }
                    >
                      <div className={"col px-2"}>
                        <img src={git} width="45px" height="auto" alt="GIT" />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={st}
                          width="35px"
                          height="auto"
                          alt="Source Tree"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={ma}
                          width="45px"
                          height="auto"
                          alt="Microsoft Azure"
                        />
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      GIT / SourceTree / AZURE
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div
                      className={
                        "d-flex flex-row justify-content-between align-items-center mx-auto"
                      }
                    >
                      <div className={"col px-2"}>
                        <img
                          src={as}
                          width="85px"
                          height="auto"
                          alt="Android Studio"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={xCode}
                          width="55px"
                          height="auto"
                          alt="xCode"
                        />
                      </div>
                      <div className={"col px-2"}>
                        <img
                          src={vs}
                          width="55px"
                          height="auto"
                          alt="VS Code"
                        />
                      </div>
                      {/* <div className={"col px-2"}>
                        <img
                          src={stt}
                          width="55px"
                          height="auto"
                          alt="Sublime Text"
                        />
                      </div> */}
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      Android Studio / xCode / VS Code
                    </h1>
                  </div>
                </div>
                <div
                  className={"col-md-4 col-12 p-2"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div
                    className={
                      "col py-4 skillBox d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div
                      className={
                        "d-flex justify-content-between align-items-center mx-auto"
                      }
                    >
                      <div
                        className={
                          "d-flex flex-row justify-content-between pt-3"
                        }
                      >
                        <div className={"col px-2"}>
                          <img
                            src={aws}
                            width="auto"
                            height="55px"
                            title="Amazon WEB Service"
                            alt="AWS"
                          />
                        </div>
                        <div className={"col px-2"}>
                          <img
                            src={awsLambda}
                            width="auto"
                            height="55px"
                            title="AWS Lambda"
                            alt="Lambda"
                          />
                        </div>
                        <div className={"col px-2"}>
                          <img
                            src={awsCF}
                            width="auto"
                            height="55px"
                            title="AWS CloudFormation"
                            alt="CloudFormation"
                          />
                        </div>
                      </div>
                    </div>
                    <h1
                      className={"text-white text-center fs-6 app-font-Rg pt-4"}
                    >
                      AWS / AWS CLI / SAM CLI
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"app-bg-dark d-flex flex-grow-1 s4 pb-5"}
          ref={Section_SC4}
          style={{ minHeight: "100vh" }}
        >
          <div className={"mainContent col-md-11 col-12 mx-auto"}>
            <div className={"objectiveContent col pt-5"}>
              <h1
                contenttext="Adventures"
                className={"text-center py-5 ttl1 app-font-EBold"}
                data-aos="fade-down"
                data-aos-delay="75"
                data-aos-duration="1000"
              >
                Experience
              </h1>
              <div className={"col pt-5 d-flex flex-row flex-wrap"}>
                <div
                  className={"col-md-6 col-12 p-3 d-flex"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div className={"card"}>
                    <div className={"card-body"}>
                      <h5 className={"card-title app-font-Md mb-4"}>
                        <i className={"icon-suitcase mr-3"}></i>
                        CitiusTech
                      </h5>
                      <h6
                        className={"card-subtitle my-2 text-muted app-font-Md"}
                      >
                        Technical Specialist (Oct 2023 - Present)
                      </h6>
                      <h6
                        className={"card-subtitle my-2 text-muted app-font-Md"}
                      >
                        Technical Lead II (Jul 2022 - Oct 2023)
                      </h6>
                      {/* <p className={"card-text app-font-Rg app-color-dark"}>
                        - Senior Application Developer{" "}
                        <strong>(Optum Technologies)</strong>
                        <br></br>- AWS/Nodejs Developer for{" "}
                        <strong>(NextGEN)</strong>
                      </p> */}
                      <div
                        className={"card-text app-font-Rg app-color-dark mb-3"}
                      >
                        - Projects Handled:&nbsp;
                        <p className={"ml-5 mb-1 d-block"}>
                          <b>Optum Technologies UHG</b> (May 2023 -
                          Present),
                        </p>
                        <p className={"ml-5 my-1 d-block"}>
                          <b>NextGEN Mobile Solution</b> (Aug 2022 - Jan 2023),
                        </p>
                      </div>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Technology:&nbsp;
                        <span className={"app-font-Md"}>
                          AWS (Lambda, DynamoDB, S3, SAM CLI, AWS CLI), ReactJS,
                          NextJS, TypeScript, JavaScript.
                        </span>
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Softwares:&nbsp;
                        <span className={"app-font-Md"}>
                          (VS Code, SourceTree, JIRA, Rally)
                        </span>
                        .
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Role:&nbsp;
                        <span className={"app-font-Md"}>
                          Senior Application Developer | AWS / NodeJS Developer
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={"col-md-6 col-12 p-3 d-flex"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div className={"card"}>
                    <div className={"card-body"}>
                      <h5 className={"card-title app-font-Md mb-4"}>
                        <i className={"icon-suitcase mr-3"}></i>
                        HCL Technologies
                      </h5>
                      <h6
                        className={"card-subtitle my-2 text-muted app-font-Md"}
                      >
                        Technical Lead I (Aug 2021 - Jul 2022)
                      </h6>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Worked as a support developer for{" "}
                        <strong>CISCO</strong>, and worked for HCL internal
                        project using NextJS.
                      </p>
                      <div
                        className={"card-text app-font-Rg app-color-dark mb-3"}
                      >
                        - Projects Handled:
                        <p className={"ml-5 my-1 d-block"}>
                          <b>CISCO PSC (Prime Service Catalog)</b> ITAAS,
                        </p>
                        <p className={"ml-5 mb-1 d-block"}>
                          <b>Kriya</b> HCL internal project,
                        </p>
                      </div>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Technology:{" "}
                        <span className={"app-font-Md"}>
                          ReactJS, NextJS, Bootstrap v5, HTML5 , SCSS ,
                          TypeScript , JavaScript, GitLab, SourceTree.
                        </span>
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Softwares:{" "}
                        <span className={"app-font-Md"}>
                          (VS Code, SourceTree, RoboMongo, JIRA, AZURE, Termius)
                        </span>
                        .
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Role:{" "}
                        <span className={"app-font-Md"}>
                          Fullstack Developer
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={"col-md-6 col-12 p-3 d-flex"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div className={"card"}>
                    <div className={"card-body"}>
                      <h5 className={"card-title app-font-Md mb-4"}>
                        <i className={"icon-suitcase mr-3"}></i>
                        Maveric systems limited
                      </h5>
                      <h6
                        className={"card-subtitle my-2 text-muted app-font-Md"}
                      >
                        Principle Software Engineer (March 2020 - Jul 2021)
                      </h6>
                      <h6
                        className={
                          "card-subtitle mt-2 mb-4 text-muted app-font-Md"
                        }
                      >
                        Senior Software Engineer (Apr 2018 - March 2020)
                      </h6>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - In Charge of handling team , Code Standards and
                        Maintenance , Server handling , Technology decision.
                      </p>
                      <div
                        className={"card-text app-font-Rg app-color-dark mb-3"}
                      >
                        - Projects Handled:
                        <p className={"ml-5 my-1 d-block"}>
                          <b>RapidTest</b> (Automation script tool) ,
                        </p>
                        <p className={"ml-5 mb-1 d-block"}>
                          <b>Quest</b> (Online Assessment Tool),
                        </p>
                        <p className={"ml-5 mb-1 d-block"}>
                          <b>Platform</b> (Project, User Maintenance Tool),
                        </p>
                        <p className={"ml-5 mb-1 d-block"}>
                          <b>TeamsRT</b> (Integrated with Microsoft Teams
                          Application)
                        </p>
                      </div>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Technology:{" "}
                        <span className={"app-font-Md"}>
                          MEAN | MERN, Bootstrap v4, HTML5 , SCSS , TypeScript ,
                          JavaScript, GitLab, SourceTree, Teams SDK & App Studio
                        </span>
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Softwares:{" "}
                        <span className={"app-font-Md"}>
                          (VS Code, SourceTree, RoboMongo, JIRA, AZURE, Termius)
                        </span>
                        .
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Role:{" "}
                        <span className={"app-font-Md"}>
                          Fullstack Developer
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={"col-md-6 col-12 p-3 d-flex"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <div className={"card"}>
                    <div className={"card-body"}>
                      <h5 className={"card-title app-font-Md mb-3"}>
                        <i className={"icon-suitcase mr-3"}></i>
                        Octoze Technologies
                      </h5>
                      <h6
                        className={
                          "card-subtitle mt-2 mb-4 text-muted app-font-Md"
                        }
                      >
                        Software Engineer - (Jan 2016 - March 2018)
                      </h6>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Collaborate with teams of both (
                        <span className={"app-font-Md"}>Development</span> and{" "}
                        <b>Implementation</b>) to gathering requirements for new
                        features and modules.
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Responsible for both Front-End and Back-End works to
                        develop and maintain new features and modules in
                        existing SaaS based ERP applications.
                      </p>
                      <div
                        className={"card-text app-font-Rg app-color-dark mb-3"}
                      >
                        - Projects Handled:
                        <p className={"ml-5 my-1 d-block"}>
                          <b>CAMU</b> (College and School Management for Staffs)
                          ,
                        </p>
                        <p className={"ml-5 mb-1 d-block"}>
                          <b>MyCAMU</b> (College and School Management for
                          Students),
                        </p>
                        <p className={"ml-5 mb-1 d-block"}>
                          <b>CAMU-HR</b> (HR Portal Management for Staffs),
                        </p>
                      </div>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Technology:{" "}
                        <span className={"app-font-Md"}>
                          (NodeJS, ExpressJS, MongoDB, AngularJS, Html5, CSS3,
                          Bootstrap3, JavaScript, Jquery)
                        </span>
                        .
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Softwares:{" "}
                        <span className={"app-font-Md"}>
                          (Sublime3, GIT, Redmine Issue Tracker)
                        </span>
                        .
                      </p>
                      <p className={"card-text app-font-Rg app-color-dark"}>
                        - Role: <span className={"app-font-Md"}>MEAN</span>{" "}
                        Stack Developer.
                      </p>
                    </div>
                  </div>
                </div>
                {isLoadMore && (
                  <>
                    <div
                      className={"col-md-6 col-12 p-3 d-flex"}
                      data-aos="fade-up"
                      data-aos-delay="75"
                      data-aos-duration="1000"
                    >
                      <div className={"card"}>
                        <div className={"card-body"}>
                          <h5 className={"card-title app-font-Md mb-3"}>
                            <i className={"icon-suitcase mr-3"}></i>
                            Darmatek Pvt Ltd
                          </h5>
                          <h6
                            className={
                              "card-subtitle mt-2 mb-4 text-muted app-font-Md"
                            }
                          >
                            Junior Programmer - (May 2015 - Nov 2015)
                          </h6>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Responsible for developing UI from photoshop
                            design using <b>HTML5, CSS, JavaScript, JQuery</b>.
                            Developed Responsive Grid system using custom CSS.
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - As a Junior Developer I worked in Front-End and
                            Backend for modifying UI and bug fixing in existing
                            modules.
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Projects Handled
                            <b>
                              (EShop FNA application, College Systems, Time
                              Management Systems)
                            </b>
                            .
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Technology:
                            <b>
                              (Html5, CSS3, JavaScript, JQuery, SQL workbench,
                              C#)
                            </b>
                            .
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Softwares: <b>(Visual Studio)</b>.
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Role: <b>Junior UI Developer</b>.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={"col-md-6 col-12 p-3 d-flex"}
                      data-aos="fade-up"
                      data-aos-delay="75"
                      data-aos-duration="1000"
                    >
                      <div className={"card"}>
                        <div className={"card-body"}>
                          <h5 className={"card-title app-font-Md mb-3"}>
                            <i className={"icon-suitcase mr-3"}></i>
                            Freelancer
                          </h5>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Worked as a freelance Fullstack | MEAN Stack
                            developer for (Innovin Exports – Automotive &
                            Garments), (Avigna Properties Pvt ltd - Villas,
                            Apartments builders), (Homdecor – Interiors, &
                            Designs )
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Worked as Hour based support developer for
                            existing Angular v1.7, and React JS Application.
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Collaborate with customer to gathering
                            requirements and competitive analysis..
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Responsible for developing Website including email
                            services with the help of Express.js, Node.js,
                            Angular, Node-mailer, Bootstrap, HTML5 / CSS3 /
                            JavaScript / jQuery.
                          </p>
                          <p className={"card-text app-font-Rg app-color-dark"}>
                            - Basic SEO (Search Engine Optimization), Indexing,
                            Google Analytics, Search Console, Deployed in
                            cloud-based server AWS (Amazon Web Services).
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-12 py-5 text-center">
                  <font
                    size="4"
                    className="text-white crsr_pntr"
                    onClick={setLoadMore}
                  >
                    {isLoadMore ? "See Less" : "Load More.."}
                  </font>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"app-bg-dark d-flex flex-grow-1 s5 pb-5"}
          ref={Section_SC5}
          style={{ minHeight: "100vh" }}
        >
          <div className={"mainContent col-md-11 col-12 mx-auto"}>
            <div className={"objectiveContent col pt-5"}>
              <h1
                contenttext="Communication"
                className={"text-center py-5 ttl1 app-font-EBold"}
                data-aos="fade-down"
                data-aos-delay="75"
                data-aos-duration="1000"
              >
                Contact
              </h1>
              <div className={"col pt-md-5 pt-sm-1 d-flex flex-row flex-wrap"}>
                <div
                  className={"col-11 col-md-4 mx-auto"}
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  <font
                    className={
                      "fs-3 app-color-white app-font-Md ttl-sub-1 d-inline-block"
                    }
                  >
                    Personal Details
                  </font>
                  <div className={"col pt-4"}>
                    <div
                      className={
                        "col d-flex align-items-center text-white py-3"
                      }
                    >
                      <span
                        className={
                          "d-inline-block mr-3 text-white fs-4 mr-3 col-1 app-font-Md"
                        }
                      >
                        {/* Full Name */}
                        <i className={"icon-user mx-3"}></i>
                      </span>
                      <span className={"d-inline-block mr-2 col"}>:</span>
                      <span className={"d-inline-block text-white fs-4 col-10"}>
                        Yuvaraj Gopi
                      </span>
                    </div>
                    <div
                      className={
                        "col d-flex align-items-center text-white pb-3"
                      }
                    >
                      <span
                        className={
                          "d-inline-block mr-3 text-white fs-4 col-1 app-font-Md"
                        }
                      >
                        {/* Mobile */}
                        <i className={"icon-phone-call mx-3"}></i>
                      </span>
                      <span className={"d-inline-block mr-2 col"}>:</span>
                      <a
                        className={
                          "no_dcrtn d-inline-block text-primary fs-4 col-10"
                        }
                        href="tel:+91 9003021674"
                      >
                        +91 9003021674
                      </a>
                    </div>
                    <div
                      className={
                        "col d-flex align-items-center text-white pb-3"
                      }
                    >
                      <span
                        className={
                          "d-inline-block mr-3 text-white fs-4 col-1 app-font-Md"
                        }
                      >
                        {/* Mail */}
                        <i className={"icon-email mx-3"}></i>
                      </span>
                      <span className={"d-inline-block mr-2 col"}>:</span>
                      <a
                        className={
                          "no_dcrtn d-inline-block text-primary fs-4 col-10"
                        }
                        target="blank"
                        href="mailto:yuvamac@outlook.com?subject=Enquiry Mail"
                      >
                        yuvamac@outlook.com
                      </a>
                    </div>
                    {/* <div className={'col d-flex align-items-center text-white pb-3'}>
                                            <span className={'d-inline-block mr-3 text-white fs-6 col-3 app-font-Md'}>Degree</span>
                                            <span className={'d-inline-block mx-2 col'}>:</span>
                                            <span className={'d-inline-block text-white fs-6 col-8'}>B.E. Computer Science & Engineering</span>
                                        </div>
                                        <div className={'col d-flex align-items-center text-white pb-3'}>
                                            <span className={'d-inline-block mr-3 text-white fs-6 col-3 app-font-Md'}>Work</span>
                                            <span className={'d-inline-block mx-2 col'}>:</span>
                                            <span className={'d-inline-block text-white fs-6 col-8'}>SDE 2 - MEAN Stack | Fullstack Developer @ Maveric-Systems Limited</span>
                                        </div> */}
                    <div
                      className={
                        "col d-flex align-items-center text-white pb-3"
                      }
                    >
                      <span
                        className={
                          "d-inline-block mr-3 text-white fs-4 col-1 app-font-Md"
                        }
                      >
                        {/* Location */}
                        <i className={"icon-map mx-3"}></i>
                      </span>
                      <span className={"d-inline-block mr-2 col"}>:</span>
                      <span className={"d-inline-block text-white fs-4 col-10"}>
                        Chennai, Tamil Nadu, India.
                      </span>
                    </div>
                    <div className={"w-200 py-4 text-left"}>
                      <button
                        className={"cstm_btn"}
                        type="button"
                        value="Download Resume"
                      >
                        <a
                          className={"no-dcrtn"}
                          download="Yuvaraj.docx"
                          href={CV}
                        >
                          Download CV
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "w-sm-75 w-md-40 pt-sm-3 mx-auto d-flex flex-row justify-content-center align-items-center"
                  }
                  data-aos="fade-up"
                  data-aos-delay="75"
                  data-aos-duration="1000"
                >
                  {/* <div className={'col w-100 d-flex flex-row justify-content-between align-items-end pt-2 pb-4'}>
                                        <input className={'form-control cstm_input flex-grow-1'} type="text" placeholder="Your Name" required id="name">
                                        </input>
                                    </div>
                                    <div className={'col w-100 d-flex flex-row align-items-center pb-4'}>
                                        <input className={'form-control cstm_input flex-grow-1'} type="text" placeholder="Your Email" required id="email">
                                        </input>
                                    </div>
                                    <div className={'col w-100 d-flex flex-row align-items-center pb-4'}>
                                        <textarea className={'form-control cstm_input flex-grow-1'} type="text" placeholder="Your Message" required id="email">
                                        </textarea>
                                    </div>
                                    <div className={'col w-100 text-center'}>
                                        <button className={'cstm_btn'} type="button" value="Send Email">
                                            SUBMIT
                                            </button>
                                    </div> */}
                  <div className={"col px-3 text-center"}>
                    <i
                      className={"icon-linkedin fs-1 lnkdIn crsr_pntr d-block"}
                      onClick={(e) => {
                        window.open(
                          `https://www.linkedin.com/in/yuvaraj-gopi-84a10251`
                        );
                      }}
                    ></i>
                    <h4 className={"text-white py-3"}>LinkedIn</h4>
                  </div>
                  <div className={"col px-3 text-center"}>
                    <i
                      className={"icon-twitter fs-1 twitter crsr_pntr d-block"}
                      onClick={(e) => {
                        window.open(`https://twitter.com/yuvaazz`);
                      }}
                    ></i>
                    <h4 className={"text-white py-3"}>Twitter</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SideBar activeSection={activeSection} onMenuClick={menuClick} />
    </div>
  );
}

export default LayoutComponent;
