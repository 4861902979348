import React, { useState, useEffect } from "react";
import "./sidebar.scss";

let allMenus = [
  { name: "S1", isActive: true, icon: "icon-home", menuName: "Home" },
  { name: "S2", isActive: false, icon: "icon-user", menuName: "Myself" },
  { name: "S3", isActive: false, icon: "icon-pencil", menuName: "Skills" },
  {
    name: "S4",
    isActive: false,
    icon: "icon-suitcase",
    menuName: "Experience",
  },
  { name: "S5", isActive: false, icon: "icon-email", menuName: "Contact" },
];

function SideBar({ ...props }) {
  var [menus, setMenus] = useState(allMenus);
  var [aSection, setASection] = useState("");

  const assignActive = (index, scVal) => {
    let activeMenu = null;
    let modifyMenu = menus.map((e, i) => {
      if (i === index || scVal === e.name) {
        e.isActive = true;
        activeMenu = e;
      } else e.isActive = false;
      return e;
    });
    setMenus(modifyMenu);
    setASection(activeMenu?.name);
    props.activeSection = activeMenu?.name;
    props.onMenuClick(activeMenu?.name);
  };

  useEffect(() => {
    setASection(props?.activeSection);
    assignActive(null,props?.activeSection )
  }, [props?.activeSection]);

  return (
    <div className={"h-100 sidebarBg flex-grow-0 d-sm-none d-md-block"}>
      <div
        className={
          "d-flex flex-column h-100 justify-content-center align-items-center"
        }
      >
        <div
          className={`sideBarColumn d-flex flex-column justify-content-center align-items-center ${aSection} ${
            aSection === "S1" ? "app-bg-dark" : "app-bg-white"
          }`}
        >
          {/* <div className={`sideBarColumn d-flex flex-column justify-content-center align-items-center sidebarBg`}> */}
          {menus
            ? menus.map((e, i) => {
                return (
                  <div key={i} className={"py-4 px-2"}>
                    <i
                      menuname={e.menuName}
                      onClick={(e) => {
                        assignActive(i, null);
                      }}
                      className={`menuItem ${
                        aSection === "S1" ? "app-color-grey" : "app-color-dark"
                      } ${e.icon} ${e.isActive ? "active" : ""} crsr_pntr fs-4`}
                    ></i>
                  </div>
                );
              })
            : false}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
